<template>
  <form @submit.prevent="validate" style="height: 240px">
    <input type="text" v-model="endereco.endereco" class="special-input" placeholder="Endereço" required/>
    <div style="display:flex;flex-direction:row;">
      <input type="text" v-model="endereco.numero" class="special-input" placeholder="Número" required/>
      <input type="text" v-model="endereco.complemento" class="special-input" placeholder="Complemento "/>
    </div>
    <div style="display:flex;flex-direction:row;">
      <input type="text" v-model="endereco.bairro" class="special-input" placeholder="Bairro" required/>
      <select v-model="endereco.uf" class="special-input" required>
        <option disabled selected value="">Selecione o estado</option>
        <option value="AC">Acre</option>
        <option value="AL">Alagoas</option>
        <option value="AP">Amapá</option>
        <option value="AM">Amazonas</option>
        <option value="BA">Bahia</option>
        <option value="CE">Ceará</option>
        <option value="DF">Distrito Federal</option>
        <option value="ES">Espírito Santo</option>
        <option value="GO">Goiás</option>
        <option value="MA">Maranhão</option>
        <option value="MT">Mato Grosso</option>
        <option value="MS">Mato Grosso do Sul</option>
        <option value="MG">Minas Gerais</option>
        <option value="PA">Pará</option>
        <option value="PB">Paraíba</option>
        <option value="PR">Paraná</option>
        <option value="PE">Pernambuco</option>
        <option value="PI">Piauí</option>
        <option value="RJ">Rio de Janeiro</option>
        <option value="RN">Rio Grande do Norte</option>
        <option value="RS">Rio Grande do Sul</option>
        <option value="RO">Rondônia</option>
        <option value="RR">Roraima</option>
        <option value="SC">Santa Catarina</option>
        <option value="SP">São Paulo</option>
        <option value="SE">Sergipe</option>
        <option value="TO">Tocantins</option>
      </select>
    </div>
    <div style="display:flex;flex-direction:row;">
      <input type="text" v-model="endereco.cidade" class="special-input" placeholder="Cidade" required/>
      <input 
        v-model="endereco.cep"
        class="special-input"
        placeholder="CEP"
        v-mask="mask"
        :class="{invalid: cepInvalid}"
      />
    </div>
    <button type="submit" class="special-submit">Enviar</button>
  </form>
</template>
<script>
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
export default {
  name: 'EnderecoInput',
  prop: {
  },
  data () {
    return {
      endereco: {},
      mask: { mask: '00000-000' },
      cepInvalid: false
    }
  },
  methods: {
    validate () {
      if(!this.endereco.cep) {
        this.cepInvalid = true
        return
      }
      if(this.endereco.cep.length < 9) {
        this.cepInvalid = true
        return
      }
      this.endereco.cep = this.endereco.cep.substring(0,9)
      this.endereco.str = `${this.endereco.endereco}, ${this.endereco.numero} ${(this.endereco.complemento) ? this.endereco.complemento: '' } - ${this.endereco.bairro} - ${this.endereco.cidade}/${this.endereco.uf}, ${this.endereco.cep}`
      this.$emit('submit', this.endereco)
    }
  }
}
</script>