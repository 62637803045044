/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */

export const validateCpf = (cpf) => {
    cpf = cpf.replace(/[^\d]+/g,'')
    let Soma
    let Resto
    Soma = 0
    if (cpf === '00000000000') return 'Número inválido'
     
    for (let i=1; i<=9; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    Resto = (Soma * 10) % 11
   
    if ((Resto == 10) || (Resto == 11))  Resto = 0
    if (Resto != parseInt(cpf.substring(9, 10)) ) return 'Número inválido'
   
    Soma = 0
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
    Resto = (Soma * 10) % 11
   
    if ((Resto == 10) || (Resto == 11))  Resto = 0
    if (Resto != parseInt(cpf.substring(10, 11) ) ) return 'Número inválido'
    return true
}

export const validateCnpj = (cnpj) => { 
    cnpj = cnpj.replace(/[^\d]+/g,'')
    
    if(cnpj == '') return 'Número inválido'
        
    if (cnpj.length != 14)
        return 'Número inválido'
    
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == '00000000000000' || 
        cnpj == '11111111111111' || 
        cnpj == '22222222222222' || 
        cnpj == '33333333333333' || 
        cnpj == '44444444444444' || 
        cnpj == '55555555555555' || 
        cnpj == '66666666666666' || 
        cnpj == '77777777777777' || 
        cnpj == '88888888888888' || 
        cnpj == '99999999999999')
        return 'Número inválido'
            
    // Valida DVs
    let tamanho = cnpj.length - 2
    let numeros = cnpj.substring(0,tamanho)
    let digitos = cnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2)
            pos = 9
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado != digitos.charAt(0))
        return 'Número inválido'
            
    tamanho = tamanho + 1
    numeros = cnpj.substring(0,tamanho)
    soma = 0
    pos = tamanho - 7
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2)
            pos = 9
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado != digitos.charAt(1))
        return 'Número inválido'
            
    return true
}

export const validateCpfCnpj = (cpfOrCnpj) => {
    let cpf = validateCpf(cpfOrCnpj)
    let cnpj = validateCnpj(cpfOrCnpj)
    if (typeof cpf === 'string' && typeof cnpj === 'string') return 'Número inválido'
    if (cpf) return true
    if (cnpj) return true
    return false
}

export const validateByFunctionName = (functionName, value, ...params) => {
    return eval(functionName)(value, ... params)
}

export const diaDepoisDeHoje = (date)=> {
    let dt = null
    if (date instanceof Date) {
      dt = date.toDateString().replace(/-/g, '/')
    } else {
      dt = date.toString().replace(/-/g, '/')
    }
    if (new Date(dt) <= new Date(new Date().toDateString())) {
        return "selecione uma data a partir de amanhã"
    }
    return  true
}

export const ateNDiasApos = (date, n) => {
    if(typeof n === 'object') n = n.n
    let dt = null
    if (date instanceof Date) {
      dt = date.toDateString().replace(/-/g, '/')
    } else {
      dt = date.toString().replace(/-/g, '/')
    }
    var dateAfter = new Date();
    dateAfter.setDate(dateAfter.getDate() + n);
    if (new Date(dt) > new Date(dateAfter.toDateString())) {
        return `selecione uma data até ${n} dias`
    }
    return  true
}