<template>
  <div id="app">
    <div class="chat-outter-container">
      <div class="chat-icon" :class="chatState" @click="openOrCloseClick"></div>
      <transition name="fade">
        <div
          class="chat-welcome-message"
          v-if="showMessage"
          @click="openOrCloseClick"
        >{{$defs.welcomeMessage}}</div>
      </transition>
      <transition name="fade">
        <div class="chat-wrapper" v-if="chatState === 'chat'" :class="{isInputing: isInputing}">
          <div class="top-chat">
            <div class="logo">
              <img src="/wp-content/plugins/chatbot-condinvest/chatbot-condinvest/dist/logo.png" />
            </div>
            <div class="close" @click="() => closeChat()">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15">
                <path
                  d="M7 5.379l-4.24-4.24a1.495 1.495 0 00-2.124-.003c-.59.59-.585 1.537.003 2.124l4.24 4.24-4.24 4.24a1.495 1.495 0 00-.003 2.124c.59.59 1.537.585 2.124-.003L7 9.621l4.24 4.24a1.495 1.495 0 002.124.003c.59-.59.585-1.537-.003-2.124L9.121 7.5l4.24-4.24a1.495 1.495 0 00.003-2.124 1.498 1.498 0 00-2.124.003L7 5.379z"
                  fill="#fff"
                  fill-rule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div class="chat-container">
            <messages
              :messages="sentMessages"
              @actionSelected="processAction"
              @multipleActionSelected="processMultipleAction"
              :definedVars="definedVars"
            />
          </div>
          <div :class="{invalid: !valid}" v-if="hasInput">
            <div class="chat-input-container" :style="`opacity: ${(showInput) ? 1: 0};`">
              <form @submit.prevent="validate" v-if="!messages[getIndex()].input.formType">
                <div class="flex">
                  <div class="row" style="position: relative;">
                    <vc-date-picker locale="pt-br" v-show="inputType === 'calendar'" :value='inputedValueCalendar' @input='inputCalendar' :min-date='dateToday' :max-date='dateToday14' />
                    <input
                      v-show="inputType !== 'calendar'"
                      :placeholder="messages[getIndex()].input.placeholder"
                      v-mask="dynamicMask[messages[getIndex()].input.mask]"
                      v-model="inputedValue"
                      :class="{invalid: !valid}"
                      v-if="messages[getIndex()].input.mask"
                      :type="inputType === 'password' ? (!visibility ? 'text' : 'password') : inputType"
                      ref="inputMasked"
                      required
                      @paste="triggerInput"
                    />
                    <input
                      v-show="inputType !== 'calendar'"
                      :placeholder="messages[getIndex()].input.placeholder"
                      v-model="inputedValue"
                      :class="{invalid: !valid}"
                      :type="inputType"
                      ref="inputNotMasked"
                      v-else
                      required
                    />
                    <div @click="swapVisibility" class="eye eye-on" v-if="inputType === 'password' && visibility"></div>
                    <div @click="swapVisibility" class="eye eye-off" v-if="inputType === 'password' && !visibility"></div>
                    <button type="submit">Enviar</button>
                  </div>
                  <div class="row">
                    <span class="error-message" v-if="!valid">{{invalidMessage}}</span>
                  </div>
                </div>
              </form>
              <component :is="messages[getIndex()].input.formType" @submit="validateSpecial" v-else></component>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
//00360305000104
//giliebu02@caixa.gov.br

// import workflow from '@/workflow_alteracao_endereco'
// import workflow from '@/workflow_alteracao_dados2'
// import workflow from '@/workflow_alteracao_email'
// import workflow from '@/workflow_show_boleto'
// import workflow from '@/workflow_boleto_vencido'
// import workflow from "@/workflow_boletos";
// import workflow from '@/workflow_boletosVinteDias'
// import workflow from '@/workflow_init'
// import workflow from '@/workflow'
import workflow from '@/workflow_prod'
import Messages from "@/components/Messages";
import { validateByFunctionName } from "@/validators";
import api from "@/Api";
import EnderecoCompleto from "@/components/EnderecoInput";

const formatDate = d => Date(d).toLocaleString().split(" ").splice(1,3).reduce((arr, x) => x.length > 2 ? [...arr, x] : [x, ...arr], []).join(" ")

/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
export default {
  name: "app",
  components: {
    Messages,
    EnderecoCompleto
  },
  methods: {
    inputCalendar(v) {
      this.inputedValue = v.toISOString();
      this.inputedValueCalendar = v;
    },
    openOrCloseClick() {
      if (this.chatState === "closed") {
        this.chatState = "chat";
        this.$ga.event({
          eventCategory: 'chatbot',
          eventAction: 'click',
          eventLabel: 'Abriu Chatbot'
        })
      } else {
        this.closeChat();
      }
    },
    endChat() {
      this.$ga.event({
        eventCategory: 'chatbot',
        eventAction: 'click',
        eventLabel: 'Abriu Chatbot'
      })
      this.chatState = "closed";
      this.showMessage = true;
      this.messages = JSON.parse(JSON.stringify(workflow.messages));
      this.messageIndex = 0;
      this.sentMessages = [];
      this.chatStarted = false;
      this.definedVars = [];
      this.forceEnd = false;
    },
    reset () {
      this.chatState = 'chat'
      this.messages = JSON.parse(JSON.stringify(workflow.messages));
      this.messageIndex = 0;
      this.sentMessages = [];
      this.chatStarted = false;
      this.definedVars = [];
      this.forceEnd = false;
    },
    closeChat() {
      if (this.definedVars["chatEnded"]) this.endChat();
      if (!this.forceEnd && workflow.exit) {
        this.forceEnd = true;
        workflow.exit(
          this.definedVars,
          messages => {
            this.stopMessaging();
            this.messageIndex = 0;
            this.messages = messages;
            this.startMessaging();
          },
          this.endChat
        );
      } else {
        this.endChat();
      }
    },
    stopMessaging() {
      clearInterval(this.intervalID);
    },
    startMessaging() {
      this.intervalID = setInterval(
        this.addMessage,
        this.$defs.messagingInterval
      );
    },
    checkStopMessage() {
      if (this.messages[this.messageIndex]?.type === this.stopCurrentMessageToGotoQueue) {
        this.stopMessaging();
        if(this.queuedMessages && this.queuedMessages.length > 0) {
          this.processQueue();
        }
        return;
      }
    },
    async addMessage() {
      this.checkStopMessage()
      
      if(this.messages[this.messageIndex]?.vars?.chatReset){
        this.reset();
        return;
      }

      // console.log(this.messages[this.messageIndex], this.messages[this.messageIndex]?.vars?.link)
      // console.log(this.messages[this.messageIndex], this.messages[this.messageIndex]?.actions?.[0]?.vars?.token)
      // console.log(this.messages[this.messageIndex]);
      if(this.messages[this.messageIndex]?.actions?.[0]?.vars?.token){
        this.token = `Bearer ${this.messages[this.messageIndex]?.actions?.[0]?.vars?.token}`;
      }

      if(this.messages[this.messageIndex]?.vars?.token){
        this.token = `Bearer ${this.messages[this.messageIndex]?.vars?.token}`;
      }

      if(this.messages[this.messageIndex]?.vars?.link){
        window.open(this.messages[this.messageIndex].text, '_blank');
        this.messages[this.messageIndex].text = "Caso o documento não tenha carregado automáticamente <a style=\"color: #002558 !important;\" href=\"" + this.messages[this.messageIndex].text + "\" target=\"_blank\">clique aqui</a>";
      }

      if (this.messages[this.messageIndex].type === "condition") {
        let message = this.messages[this.messageIndex];
        let left = message.eval.left;
        let right = message.eval.right;
        let operator = message.eval.operator;

        if (typeof message.eval.left === "string") {
          left = this.definedVars[message.eval.left];
          if (typeof this.definedVars[message.eval.left] === "string")
            left = `'${this.definedVars[message.eval.left]}'`;
        }

        if (typeof message.eval.right === "string") {
          right = this.definedVars[message.eval.right];
          if (typeof this.definedVars[message.eval.right] === "string")
            right = `'${this.definedVars[message.eval.right]}'`;
        }

        if (message.eval.beforeLeft) {
          left = `${message.eval.beforeLeft}${left}`;
        }
        if (message.eval.beforeRight) {
          left = `${message.eval.beforeRight}${right}`;
        }
        if (message.eval.afterLeft) {
          left = `${left}${message.eval.afterLeft}`;
        }
        if (message.eval.afterRight) {
          left = `${right}${message.eval.afterRight}`;
        }

        if (eval(`${left} ${operator} ${right}`)) {
          this.messageIndex = 0;
          this.messages = message.messages;
        } else {
          this.messageIndex++;
        }
      }

      if (this.messages[this.messageIndex]?.vars) {
        this.addVars(this.messages[this.messageIndex].vars);
      }

      if (this.messages[this.messageIndex].type === "ajax") {
        this.stopMessaging();
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("input");
        let vars = this.getVars(token);

        this.sentMessages.push({
          type: "internal",
          makingApiCall: true
        });

        await api(workflow.baseURL)
          .post(this.messages[this.messageIndex].url, vars, 
            { headers: { 'Authorization': this.token || '' } }
          )
          .then(async result => {
            // console.log(result.data)
            this.sentMessages.pop();

            if (result.data.filename && result.data.url) {
              await api(workflow.baseURL).downloadFile(
                result.data.url,
                result.data.filename
              );
              this.sentMessages.push({
                type: "internal",
                text: `Caso o download não começe automaticamente <a target="_blank" href="${result.data.url}">clique aqui para começar o download</a>`
              });
            }

            this.messageIndex = 0;
            this.messages = result.data.messages;

            this.startMessaging();
          })
          .catch(() => {
            this.sentMessages.pop();
            this.sentMessages.push({
              type: "internal",
              text:
                "Desculpe, mas não foi possível se comunicar com nossos servidores. Por favor, tente mais tarde! A condinvest agradece a compreensão."
            });
            this.stopMessaging();
          });
      } else {
        this.sentMessages.push(this.messages[this.messageIndex]);
        this.messageIndex++;
        if (this.messageIndex > this.messages.length - 1) this.stopMessaging();
      }
    },
    processAction(action) {
      this.sentMessages.push(action);
      this.messageIndex = 0;
      this.messages = action.messages;

      if (action.vars) {
        this.addVars(action.vars);
      }
      this.startMessaging();
    },
    async processMultipleAction(actions) {
      let message = "";
      actions.forEach((el, index) => {
        message = `${message} ${el.text}<br>`;
        if(actions[index].vars) actions[index].vars.lastAction = false
      });
      if(actions[actions.length-1].vars) {
        actions[actions.length-1].vars.lastAction = true
      }
      this.sentMessages.push({
        type: "action",
        text: message
      });
      this.queuedMessages = actions;
      this.stopCurrentMessageToGotoQueue = "actions";
      this.processQueue()
    },
    processQueue() {
      const message = this.queuedMessages.shift();
      this.sentMessages.push({
        type: "internal",
        text: message.text
      });
      if (message.vars) this.addVars(message.vars);
      this.messageIndex = 0;
      this.messages = message.messages;
      if(this.queuedMessages.length === 0) {
        this.stopCurrentMessageToGotoQueue = null;
      }          
      this.startMessaging();
    },
    getIndex() {
      return this.messageIndex === 0 ? 0 : this.messageIndex - 1;
    },
    addVars(vars) {
      let values = Object.values(vars);
      Object.keys(vars).forEach((el, index) => {
        this.definedVars[el] = values[index];
      });
    },
    displayInput() {
      let time =
        this.messages.length > 1
          ? this.$defs.messagingInterval * this.messages.length * 0.5
          : this.$defs.messagingInterval * this.messages.length * 2;
      setTimeout(() => (this.showInput = true), time);
    },
    async validate() {
      let formType = this.messages[this.getIndex()].input.formType;
      if (!formType) {
        if (!this.inputedValue) {
          this.valid = false;
          this.invalidMessage = "Campo Obrigatório";
          return;
        }

        if (this.currentMask) {
          this.inputedValue = this.currentMask.value.substring(
            0,
            this.currentMask.mask.length
          );
          if (this.inputedValue.length !== this.currentMask.mask.length) {
            this.valid = false;
            this.invalidMessage = "Valor inválido";
            return;
          }
        }  
       if(this.messages[this.getIndex()].input.name === "password_confirmation" && this.inputedValue !== this.getVars()['password']) {
          this.valid = false;
          this.invalidMessage = "Confirmação de senha incorrespondente";
          return;
        }
      }

      let action = this.messages[this.getIndex()];
      let validation = action.input.validation;

      if (validation && validation.local) {
        let valid = true
        if(typeof validation.local === 'object') {
          for(let i = 0; i < validation.local.length; i++) {
            if(validation.local[i].params) {
              valid = validateByFunctionName(validation.local[i].name, this.inputedValue, validation.local[i].params);
            } else {
              valid = validateByFunctionName(validation.local[i].name, this.inputedValue);
            }
            if (typeof valid === "string") {
              this.valid = false;
              this.invalidMessage = valid;
              return;
            }
          }
        } else {
          valid = validateByFunctionName(validation.local, this.inputedValue);
          if (typeof valid === "string") {
            this.valid = false;
            this.invalidMessage = valid;
            return;
          }
        }
      }

      this.showInput = false;
      this.messageIndex++;
      this.valid = true;
      this.currentMask = null;

      // is valid
      let valueInput = formType ? this.specialInput.str : this.inputedValue;
      // console.log(valueInput)
      this.sentMessages.push({
        text: action.input.inputType === 'password' ? Array(valueInput.length).fill('•').join('') : ((action.input.inputType === 'date') ? this.toBrDate(valueInput) : (action.input.inputType === 'calendar' ? formatDate(valueInput) : valueInput)),
        type: "response"
      });

      if (action.input.name) {
        if (action.input.formType) {
          this.definedVars[action.input.name] = this.specialInput;
        } else {
          this.definedVars[action.input.name] = this.inputedValue;
        }
      }
      this.inputedValue = "";
      this.messageIndex = 0;

      if (validation && validation.api) {
        this.sentMessages.push({
          type: "internal",
          makingApiCall: true
        });

        await this.$recaptchaLoaded();
        const token = await this.$recaptcha("input");

        //api call
        let vars = this.getVars(token);

        await api(workflow.baseURL)
          .post(validation.api, vars)
          .then(result => {
            // console.log(result.data.isValid)
            if (result.data.isValid) {
              if (validation.showApiReturn) {
                action.messages = result.data.messages;
                if (result.data.vars) {
                  this.addVars(result.data.vars);
                }
              }
            } else {
              if (validation.showInvalidApiReturn && result.data.messages) {
                action.messages = result.data.messages;
              } else {
                action.messages = action.input.invalid.messages;
              }
            }

            this.sentMessages.pop();

            if (action.messages.length > 0) {
              this.messages = action.messages;
              this.startMessaging();
            }
          })
          .catch(() => {
            this.sentMessages.pop();
            this.sentMessages.push({
              type: "internal",
              text:
                "Desculpe, mas não foi possível se comunicar com nossos servidores. Por favor, tente mais tarde! A condinvest agradece a compreensão."
            });
            this.stopMessaging();
          });
      } else if (action.messages.length > 0) {
        this.messages = action.messages;
        this.startMessaging();
      }
    },
    changeMask(evt) {
      this.inputedValue = (evt.originalEvent || evt).clipboardData.getData(
        "text/plain"
      );
    },
    getVars(grecaptcha) {
      let vars = { grecaptcha };
      let values = Object.values(this.definedVars);
      Object.keys(this.definedVars).forEach((value, index) => {
        vars = {
          [value]: values[index],
          ...vars
        };
      });
      return vars;
    },
    validateSpecial(data) {
      this.specialInput = data;
      this.validate();
    },
    getMask(appended, dynamicMasked) {
      this.currentMask = dynamicMasked.compiledMasks[0];
      if (
        dynamicMasked.value.length + appended.length >
        dynamicMasked.compiledMasks[0].mask.length
      ) {
        this.currentMask = dynamicMasked.compiledMasks[1];
      }
      return this.currentMask;
    },
    triggerInput() {
      setTimeout(() => {
        let evt = document.createEvent("Event");
        evt.initEvent("input", true, true);

        this.$refs.inputMasked.dispatchEvent(evt);
      }, 100);
    },
    toBrDate (date) {
      let dt = null
      if (date instanceof Date) {
        dt = date.toDateString().replace(/-/g, '/')
      } else {
        dt = date.toString().replace(/-/g, '/')
      }
      return `${new Date(dt).toLocaleDateString('pt-BR')}`
    },
    openChat() {
        this.chatState = 'chat'
    },
    swapVisibility () {
      this.visibility = !this.visibility;
    }
  },
  data() {
    return {
      chatState: "closed",
      chatStarted: false,
      showMessage: false,
      messageIndex: 0,
      messages: JSON.parse(JSON.stringify(workflow.messages)),
      sentMessages: [],
      intervalID: null,
      isInputing: false,
      inputedValue: null,
      valid: true,
      invalidMessage: "",
      definedVars: [],
      makingApiCall: false,
      showInput: false,
      specialInput: {},
      currentMask: null,
      forceEnd: false,
      token: null,
      inputedValueCalendar: null,
      visibility: true,
      dynamicMask: {
        cpfCnpj: {
          mask: [{ mask: "000.000.000-00" }, { mask: "00.000.000/0000-00" }],
          dispatch: this.getMask
        },
        phone: {
          mask: [{ mask: "(00) 0000-0000" }, { mask: "(00) 00000-0000" }],
          dispatch: this.getMask
        },
        cep: {
          mask: "00000-000"
        }
      },
      publicPath: process.env.BASE_URL,
      queuedMessages: null,
      stopCondition: null
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.chatState != "chat") this.showMessage = true;
    }, this.$defs.timeToShowMessage);
    if (window.location.hash === "#open-chat") this.openChat()
    this.$ga.event({
      eventCategory: 'chatbot',
      eventAction: 'click',
      eventLabel: 'Carregou Chatbot'
    })
  },
  watch: {
    chatState(newVal) {
      if (newVal !== "closed") this.showMessage = false;
      if (newVal === "chat" && !this.chatStarted) {
        this.chatStarted = true;
        this.addMessage();
        this.startMessaging();
      }
    },
    hasInput(newVal) {
      if (newVal === true) this.displayInput();
    },
    messages() {
      if (this.hasInput) this.displayInput();
    }
  },
  beforeDestroy() {
    this.stopMessaging();
    window.removeEventListener('openchat', this.openChat);
  },
  computed: {
    hasInput() {
      if (this.messages[this.getIndex()]) {
        if (this.messages[this.getIndex()].input) {
          let time =
            this.messages.length > 1
              ? this.$defs.messagingInterval * this.messages.length * 0.5
              : this.$defs.messagingInterval * this.messages.length * 2;

          // eslint-disable-next-line vue/no-async-in-computed-properties
          setTimeout(() => {
            const ref = this.$refs.inputMasked
              ? this.$refs.inputMasked
              : this.$refs.inputNotMasked;
              ref.focus();
          }, time + 10);

          return true;
        }
      }
      return false;
    },
    dateToday() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return today;
    },
    dateToday14(){
      const today14 = (new Date());
      today14.setHours(0, 0, 0, 0);
      today14.setDate(today14.getDate() + 14);
      return today14;
    },
    inputType() {
      if (this.messages[this.getIndex()]) {
        if (this.messages[this.getIndex()].input) {
          if (this.messages[this.getIndex()].input.inputType) {
            return this.messages[this.getIndex()].input.inputType;
          } else {
            return "text";
          }
        }
      }
      return "text";
    },
    inputMin() {
      if (this.messages[this.getIndex()]) {
        if (this.messages[this.getIndex()].input) {
          if (this.messages[this.getIndex()].input.inputMin) {
            return this.messages[this.getIndex()].input.inputMin;
          } else {
            return false;
          }
        }
      }
      return "text";
    }
  },
  created: function() {
      window.addEventListener('openchat', this.openChat);
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/main";
</style>
