export default {
  baseURL: "https://api.app.condinvest.com.br/api/chatbot/",
  //baseURL: "https://api.dev.condinvest.com.br/api/chatbot/",
  messages: [
    {
      text: "Olá, sou a Assistente Virtual da Condinvest, por aqui você poderá se cadastrar, efetuar login, recuperar senha, consultar informações do seu cadastro, boletos pagos e a pagar, obter certidão negativa de débitos, solicitar boletos, entre outras informações.",
      type: "internal",
    },
    {
      text: "Escolha uma das opções que deseja realizar:",
      type: "internal",
      actions: [
        {
          text: "Login",
          type: "action",
          messages: [
            {
              text:
                "Para realizarmos seu Login, necessitamos de algumas informações",
              type: "internal",
            },
            {
              text: "Digite o seu CPF/CNPJ (digite apenas números)",
              type: "internal",
              input: {
                name: "cpf",
                placeholder: "Digite CPF ou CNPJ",
                mask: "cpfCnpj",
              },
              messages: [
                {
                  text: "Digite sua senha",
                  type: "internal",
                  input: {
                    placeholder: "Digite sua senha",
                    mask: "password",
                    name: "password",
                    inputType: 'password'
                  },
                  messages: [
                    {
                      type: "ajax",
                      url: "login",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          text: "Cadastro",
          type: "action",
          messages: [
            {
              text:
                "Para realizarmos seu cadastro, necessitamos de algumas informações",
              type: "internal",
            },
            {
              text: "Tipo de Usuário:",
              type: "internal",
              actions: [
                {
                  text: "Proprietário",
                  type: "action",
                  messages: [
                    {
                      text: "Digite o seu CPF/CNPJ? (digite apenas números)",
                      type: "internal",
                      input: {
                        name: "cpf",
                        placeholder: "Digite CPF ou CNPJ",
                        mask: "cpfCnpj",
                      },
                      messages: [
                        {
                          text: "Digite o e-mail que deseja cadastrar",
                          type: "internal",
                          input: {
                            placeholder: "Digite seu email",
                            name: "email",
                            inputType: "email",
                          },
                          messages: [
                            {
                              text:
                                "Digite o DDD mais o Número do telefone celular constante no cadastro deste proprietário (digite apenas números)",
                              type: "internal",
                              input: {
                                placeholder:
                                  "Digite o telefone celular com o DDD",
                                mask: "phone",
                                name: "telephone",
                              },
                              messages: [
                                {
                                  text: "Digite sua senha",
                                  type: "internal",
                                  input: {
                                    placeholder: "Digite sua senha",
                                    mask: "password",
                                    name: "password",
                                    inputType: 'password'
                                  },
                                  messages: [
                                    {
                                      text: "Digite a confirmação da sua senha",
                                      type: "internal",
                                      input: {
                                        placeholder:
                                          "Digite a confirmação da sua senha",
                                        mask: "password",
                                        name: "password_confirmation",
                                        inputType: 'password'
                                      },
                                      messages: [
                                        {
                                          text: "<a style=\"color: #002558 !important;\" target=\"_blank\" href=\"https://condinvest.com.br/politica-privacidade/\">Clique aqui para ler os termos de uso</a>",
                                          type: 'internal'
                                        },
                                        {
                                          text: "Certifica todas as informações e aceita os termos de uso?",
                                          type: "internal",
                                          actions: [
                                            {
                                              text: "Sim, confirmei minhas informações, li os termos de uso e aceito-os",
                                              type: "action",
                                              messages: [
                                                {
                                                  type: "ajax",
                                                  url: "register/owner",
                                                },
                                              ],
                                            },
                                            {
                                              text: "Não, discordo dos termos de uso",
                                              type: "action",
                                              messages: [
                                                {
                                                  text:
                                                    "Desculpe, para realizar o cadastro você deve certificar as informações e aceitar os termos de uso.",
                                                  type: "internal",
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  text: "Inquilino",
                  type: "action",
                  messages: [
                    {
                      text:
                        "DIGITE O CPF/CNPJ DO PROPRIETÁRIO (DIGITE APENAS NÚMEROS)",
                      type: "internal",
                      input: {
                        name: "document",
                        placeholder: "DIGITE CPF OU CNPJ DO PROPRIETÁRIO",
                        mask: "cpfCnpj",
                      },
                      messages: [
                        {
                          type: "ajax",
                          url: "unity/login/index",
                        }
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          text: "Recuperar senha",
          type: "action",
          messages: [
            {
              text:
                "Para recuperarmos sua senha, necessitamos de algumas informações",
              type: "internal",
            },
            {
              text: "Digite o seu CPF/CNPJ (digite apenas números)",
              type: "internal",
              input: {
                name: "cpf",
                placeholder: "Digite CPF ou CNPJ",
                mask: "cpfCnpj",
              },
              messages: [
                {
                  text: "Digite seu e-mail",
                  type: "internal",
                  input: {
                    placeholder: "Digite seu e-mail",
                    mask: "email",
                    name: "email",
                    inputType: "email",
                  },
                  messages: [
                    {
                      type: "ajax",
                      url: "recover/request",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    /*{
            text: 'Você é proprietário de uma unidade condominial onde a cobrança é realizada pela Condinvest?',
            type: 'internal',
            actions: [
                {
                    text: 'Sim',
                    type: 'action',
                    messages: [
                        {
                            text: 'Para localizar seu cadastro, necessitamos de algumas informações',
                            type: 'internal',
                        },
                        {
                            text: 'Digite o seu CPF ou CNPJ? (digite apenas números)',
                            type: 'internal',
                            input: {
                                name: 'cpfCnpj',
                                placeholder: 'Digite CPF ou CNPJ',
                                mask: 'cpfCnpj',
                                validation: {
                                    local: 'validateCpfCnpj',
                                    api: '/validateCpfCnpj',
                                    showInvalidApiReturn: true
                                },
                                invalid: {
                                    messages: [
                                        {
                                            text: 'Este CPF/CNPJ não foi localizado em nossos cadastros, ou então, não foram emitidos quaisquer boletos para o mesmo, impossibilitando assim a continuidade do atendimento. Obrigado',
                                            type: 'internal'
                                        },
                                        {
                                            text: 'Obrigado por entrar em contato com a Condinvest.',
                                            type: 'internal'
                                        },
                                    ]
                                }
                            },
                            messages: [
                                {
                                    type: 'condition',
                                    eval: {
                                        left: 'cpfCnpj',
                                        afterLeft: '.length',
                                        operator: '>',
                                        right: 14
                                    },
                                    messages: [
                                        {
                                            text: 'Qual o seu e-mail?',
                                            type: 'internal',
                                            input: {
                                                placeholder: 'Digite seu email',
                                                name: 'email',
                                                inputType: 'email',
                                                validation: {
                                                    api: '/condominiosCnpj',
                                                    showApiReturn: true
                                                },
                                                invalid: {
                                                    messages: [
                                                        {
                                                            text: 'Desculpe, mas não foi possível encontrar nenhum cadastro com os dados informados.',
                                                            type: 'internal'
                                                        },
                                                        {
                                                            text: 'Obrigado por entrar em contato com a Condinvest.',
                                                            type: 'internal'
                                                        },
                                                    ]
                                                }
                                            }
                                        }
                                    ]
                                },
                                {
                                    text: 'Por segurança, preciso que me responda algumas perguntas para continuarmos seu atendimento, vamos lá:',
                                    type: 'internal'
                                },
                                {
                                    text: 'Digite o DDD mais o Número do telefone celular constante no cadastro deste proprietário (digite apenas números)',
                                    type: 'internal',
                                    input: {
                                        placeholder: 'Digite o telefone celular com o DDD',
                                        mask: 'phone',
                                        name: 'phone'
                                    },
                                    messages: [
                                        {
                                            text: 'Qual o e-mail constante no cadastro deste Proprietário?',
                                            type: 'internal',
                                            input: {
                                                placeholder: 'Digite seu email',
                                                name: 'email',
                                                inputType: 'email',
                                                validation: {
                                                    api: '/condominiosCpf',
                                                    showApiReturn: true
                                                },
                                                invalid: {
                                                    messages: [
                                                        {
                                                            text: 'Desculpe, mas não foi possível encontrar nenhum cadastro com os dados informados.',
                                                            type: 'internal'
                                                        },
                                                        {
                                                            text: 'Obrigado por entrar em contato com a Condinvest.',
                                                            type: 'internal'
                                                        },
                                                    ]
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    text: 'Não',
                    type: 'action',
                    messages: [
                        {
                            text: 'Desculpe, então não poderei ajudá-lo por aqui. Contate um de nossos atendentes pelo telefone (16) 3376-1481. Muito obrigada.',
                            type: 'internal'
                        }
                    ]
                }
            ]
        }*/
  ],
  exit: (vars, startMessaging, endChat) => {
    if (
      vars["cpfCnpj"] &&
      vars["unidade"] &&
      vars["condominio"] &&
      vars["boletoImpresso"]
    ) {
      startMessaging([
        {
          type: "internal",
          text:
            "Os boletos são enviados por e-mail quando gerados pela Condinvest, bem como às vésperas do Vencimento. Além do que, podem ser obtidos a qualquer momento por este canal, pelo WhatsApp (16) 3509-5858, bem como pelo nosso APP para Celular (Condinvest - Shinier).",
          actions: [
            {
              type: "action",
              text: "Não desejo mais o envio de boletos impressos",
              messages: [
                {
                  url: "/boletoImpresso?noReinit=true",
                  type: "ajax",
                },
              ],
            },
            {
              type: "action",
              text: "Finalizar atendimento",
              messages: [
                {
                  text:
                    "Obrigado por utilizar nossa Assistente Virtual. Até mais!",
                  type: "internal",
                  vars: {
                    chatEnded: true,
                  },
                },
              ],
            },
          ],
        },
      ]);
    } else {
      endChat();
    }
  },
};

//52322033634
//16999938228
//mauadffm@gmail.com

// 08152038881
// 16997734148
// salvador@condinvest.com.br

// 00000000000191
// gecor.servicos@bb.com.br

// 14047643000103
// renatamarchesan@hotmail.com

// 42234911800
// 16993226765
// kpaulocesar@gmail.com

//08530418816
//16981333343
//nayhara_alvares@hotmail.com

// 36461392858
// 16997305662
// janainafugiwara@gmail.com
