import Vue from 'vue'
import { IMaskDirective } from 'vue-imask';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import App from './App.vue';
import VueAnalytics from 'vue-analytics'
import VCalendar from 'v-calendar';

Vue.use(VueAnalytics, {
  id: 'UA-179758170-1'
})

Vue.use(VCalendar, {
  componentPrefix: 'vc',
})

Vue.config.productionTip = false

// Vue.use(VueMask);
Vue.directive('mask', IMaskDirective);
Vue.use(VueReCaptcha, { 
  siteKey: '6Lcz_9wUAAAAADjynkQlRWKQW-c-lGU6NtVisS4c',
  loaderOptions: {
    renderParameters: {
      badge: 'bottomleft'
    }
  },
  autoHideBadge: true
})

Vue.prototype.$defs = {
  welcomeMessage: 'Precisa de ajuda? Aqui é a Assistente Virtual da Condinvest e estou aqui para ajudá-lo!',
  timeToShowMessage: 100,
  messagingInterval: 500
}

new Vue({
  render: h => h(App),
}).$mount('#chatbot-app')
