<template>
  <div class="messages">
    <message v-for="(message, index) in messages" :key="index" 
      :message="message"
      @actionSelected="(action) => $emit('actionSelected', action)"
      :definedVars="definedVars"
    />
  </div>
</template>

<script>
import Message from '@/components/Message'

/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
export default {
  name: 'Messages',
  props: {
    messages: {
        type: Array,
        required: true
    },
    definedVars: {
      type: Array,
      required: true
    }
  },
  components: {
    Message
  },
  methods: {
    processAction (action) {
      this.$emit('actionSelected', action)
    }
  }
}
</script>

<style>

</style>
