<template>
  <div class="message" :class="message.type" ref="messageContainer">
    <div class="icon" v-if="message.type === 'internal' || message.type === 'actions'">
      <img src="/wp-content/plugins/chatbot-condinvest/chatbot-condinvest/dist/icon.png" />
    </div>
    <div class="content-wrapper" :class="{loaded : (!loading && !message.makingApiCall), wider: isWider()}">
      <div v-if="message.type !== 'special'">
        <div class="loading" v-if="loading || message.makingApiCall">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="content" v-else>
          <div v-html="processedMessage"></div>
          <div class="actions" v-if="message.actions && optionSelected">
            <div class="action"
              v-for="(action, index) in message.actions"
              :key="index" 
              @click="() => { actionSelected(action) }"
            >
              <div v-html="action.text"></div>
            </div>
          </div>
          <div class="multiple-actions actions" v-if="message.multipleActions && optionSelected">
            <div class="multiple-action"
              v-for="(action, index) in message.multipleActions"
              :key="index"
            >
              <label class="container">{{action.text}}
                <input type="checkbox" :value="action" v-model="multipleActionsOptions[index]">
                <span class="checkmark"></span>
              </label>
            </div>
            <div class="action" 
              :class="{disabled : (selectedMultipleOptions.length === 0)}"
              @click="multipleActionsHandler"
            >
              Selecionar
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <component
          :is="message.specialType"
          :message="message"
        />
      </div>
    </div>
    <div class="bottomMessage" ref="messageBottom"></div>
  </div>
</template>

<script>
/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
import BoletoMessage from '@/components/BoletoMessage'

export default {
  name: 'Message',
  props: {
    message: Object,
    hasIcon: {
      type: Boolean,
      default: true
    },
    definedVars: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: this.shouldLoad(),
      optionSelected: true,
      publicPath: process.env.BASE_URL,
      multipleActionsOptions: []
    }
  },
  methods: {
    actionSelected(action) {
      this.$ga.event({
        eventCategory: 'chatbot',
        eventAction: 'click',
        eventLabel: `${this.message.text} ${action.text}`
      })
      this.$emit('actionSelected', action); 
      this.optionSelected = false;
    },
    shouldLoad () {
      if (this.message.type === 'internal') {
        if(this.message.makingApiCall) {
          return false
        }
        return true
      } 
      return false
    },
    showMessageInScreen () {
      this.$refs.messageContainer.scrollIntoView(false)
    },
    showBottomMessageInScreen () {
      this.$refs.messageBottom.scrollIntoView(false)
    },
    isWider () {
      return this.processedMessage.includes('<table') || this.message.type === 'special' || this.message.multipleActions
    },
    multipleActionsHandler() {
      if(this.selectedMultipleOptions.length > 0) {
        this.$parent.$emit('multipleActionSelected', this.selectedMultipleOptions)
        this.optionSelected = false
      }
    }
  },
  mounted () {
    if (!this.message.makingApiCall) setTimeout( () => this.loading = false, this.$defs.messagingInterval*0.8)
    this.showMessageInScreen()
  },
  computed: {
    processedMessage () {
      if (this.message.text) {
        let str = this.message.text.replace('${', '${this.definedVars.')
        return eval('`'+str+'`')  
      }
      return ''
    },
    selectedMultipleOptions() {
      let options = []
      this.multipleActionsOptions.forEach((el, index) => {
        if(el) {
          options.push(this.message.multipleActions[index])
        }
      })
      return options
    }
  },
  watch: {
    message: {
      deep: true,
      handler () {
        this.showBottomMessageInScreen()
      }
    },
    loading (newVal) {
      if (!newVal)
        setTimeout(this.showBottomMessageInScreen, 50)
    }
  },
  components: {
    Boleto: BoletoMessage
  }
}
</script>

<style>

</style>
