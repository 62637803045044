<template>
  <div class="boleto-container" ref="container">
    <p v-if="isMobile()">Para que o código fique possa ser escaneado vire o celular:</p>
    <p v-else>Clique no código de barras para ampliá-lo</p>
    <div class="barcode-wrapper" @click="resizeContainer" ref="barcodeWrapper">  
      <svg id="barcode" ref="barcode"></svg>
    </div>
    <div>
      <p>Caso queira digitar o código, basta clicar no quadrado ao lado para copiá-lo:</p>
    </div>
    <div class="code-wrapper">
      <div class="code">{{this.message.linha}}</div>
      <div class="copy-btn" :data-clipboard-text="this.message.linha" >
        <span v-if="showToolTip" class="tooltip">Copiado!</span>
        <svg height="32px" width="32px" viewBox="-40 0 512 512"  xmlns="http://www.w3.org/2000/svg"><path d="m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0"/></svg>
      </div>
    </div>
    <p>Caso queira o boleto completo, <a target="_blank" :href="this.message.url">clique aqui</a></p>
  </div>
</template>

<script>

var JsBarcode = require('jsbarcode');
var ClipboardJS = require('clipboard');

/* eslint no-console: ["error", { allow: ["warn", "error", "log"] }] */
/* eslint no-useless-escape: "error"*/
export default {
  name: 'BoletoMessage',
  props: {
    message: Object,
    hasIcon: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    normalBarcode () {
      this.initBarcode('barcode', this.$refs.container.offsetWidth)
    },
    initBarcode (selector = 'barcode', width = this.$refs.container.offsetWidth ) {
      // console.log('reeniting')
      JsBarcode(`#${selector}`, this.message.cod_barras, {
        format: 'itf',
        height: 100,
        width: (((width*2)/830)*1.02),
        displayValue: false,
        margin: 0
      })
    },
    isMobile () {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ]

      return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem)
      })
    },
    resizeContainer () {
      if (!this.isMobile()) {
        let divWrapper = document.createElement('div')
        divWrapper.setAttribute('id', 'barcode-modal')

        let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        svg.setAttribute('id', 'barcode-modal-content')

        let close = document.createElement('div')
        close.setAttribute('id', 'close')

        close.innerHTML = 'X'
        divWrapper.appendChild(close)
        divWrapper.appendChild(svg)
        document.querySelector('body').appendChild(divWrapper)

        this.initBarcode('barcode-modal-content', divWrapper.offsetWidth*0.9)

        document.getElementById('close').onclick = () => {
          document.getElementById('barcode-modal').remove()
        }
      }
    }
  },
  data () {
    return {
      clipboard: null,
      showToolTip: false
    }
  },
  mounted () {
    // this.boleto = new Boleto(this.message.linha)
    this.initBarcode();
    window.addEventListener('resize', this.normalBarcode )
    this.clipboard = new ClipboardJS('.copy-btn')
    this.clipboard.on('success', () => {
      this.showToolTip = true
      window.setTimeout( () => { this.showToolTip = false } ,2000)
    })
    this.clipboard.on('error', e => {
      console.log(e)
    })
    this.$ga.event({
      eventCategory: 'chatbot',
      eventAction: 'click',
      eventLabel: 'Código barras boleto emitida'
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.normalBarcode);
  }
}
</script>
<style scoped lang="scss">
.boleto-container {
  color: #fff;

  .barcode-wrapper {
    margin: 0 -15px;
    padding: 20px 15px;
    background-color: #fff;
    
    & + div {
      margin-top: 1rem;
    }
  }

  > div > p {
    margin: 0;
  }

  .code-wrapper{
    display: flex;
    padding-top: 1rem;

    .code {
      padding-top: 1rem;
      flex-grow: 1;
    }
    
    svg {
      cursor: pointer;
      padding: 0.5rem;
      fill: #fff;
    }

    .copy-btn {
      position: relative; 

      .tooltip {
        position: absolute;
        left: -160%;
        background-color: #363636;
        padding: 0.5rem;
        border-radius: 5px;
        color: #fff;
        top: 50%;
        transform: translateY(-50%);

        &::before {
          border-left: 5px solid #363636;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          right: -5px;
          top: 50%;
          transform: translateY(-50%);
          position: absolute;
          content: "";
          pointer-events: none;
        }
      }
    }
  }

  p {
    margin: 0 0 15px 0;
  }
}
</style>